import { TimeEntryModel } from "@shared/service-proxies/service-proxies";
import { DateTime } from "luxon";

export class WorkdayTimeEntryModel {
    workDayStartTime: DateTime;
    workDayStopTime: DateTime;
    workDayDuration: number;
    timeEntry: TimeEntryModel;
    startsToday: boolean;
    endsToday: boolean;
    isRunning: boolean;
}

export class WorkDayDto {
    date: DateTime;
    totalDurationInSeconds?: number;
    isToday: boolean;

    private _timeEntries: WorkdayTimeEntryModel[] = [];
    readonly timeEntries: ReadonlyArray<WorkdayTimeEntryModel> = this._timeEntries;
    get underlyingTimeEntries(): ReadonlyArray<TimeEntryModel> {
        return this._timeEntries.map((wte) => wte.timeEntry);
    }
    get anyStartedToday(): boolean {
        return this._timeEntries.findIndex((wte) => wte.startsToday) >= 0;
    }

    constructor(date: DateTime, timeEntries: TimeEntryModel[]) {
        this.date = date.startOf("day");
        this.calculateIsToday();

        this._timeEntries.push(...timeEntries.map((timeEntry) => this.mapTimeEntryToWorkDay(timeEntry)));
        this.calculateTotalWorkDayDuration();
        this.sortTimeEntries();
    }

    upsertTimeEntry(timeEntry: TimeEntryModel): void {
        const index = this.getTimeEntryIndex(timeEntry.id);
        if (index < 0) {
            this._timeEntries.unshift(this.mapTimeEntryToWorkDay(timeEntry));
        } else {
            this.remapTimeEntryChanges(this._timeEntries[index], timeEntry);
        }

        this.calculateTotalWorkDayDuration();
        this.sortTimeEntries();
    }

    remapTimeEntryChanges(existingTimeEntry: WorkdayTimeEntryModel, timeEntry: TimeEntryModel): void {
        const result = this.mapTimeEntryToWorkDay(timeEntry);

        existingTimeEntry.workDayStartTime = result.workDayStartTime;
        existingTimeEntry.workDayStopTime = result.workDayStopTime;
        existingTimeEntry.workDayDuration = result.workDayDuration;
        existingTimeEntry.startsToday = result.startsToday;
        existingTimeEntry.endsToday = result.endsToday;
        existingTimeEntry.isRunning = result.isRunning;
        existingTimeEntry.timeEntry = timeEntry;
    }

    mapTimeEntryToWorkDay(timeEntry: TimeEntryModel): WorkdayTimeEntryModel {
        const isRunnning = timeEntry.stopTime.year <= 1;
        const startsToday = timeEntry.startTime.startOf("day").equals(this.date);
        const endsToday = timeEntry.stopTime.startOf("day").equals(this.date);
        const workDayStartTime = startsToday ? timeEntry.startTime : this.date;
        //const workDayStopTime = (isRunnning && !startsToday) || endsToday ? timeEntry.stopTime : this.date.endOf("day");
        const workDayStopTime = endsToday
            ? timeEntry.stopTime
            : (isRunnning && !this.isToday) || !this.isToday
                ? this.date.endOf("day")
                : timeEntry.stopTime;
        //const workDayDuration = !(isRunnning && !startsToday) ? workDayStopTime.diff(workDayStartTime, "seconds").as("seconds") : timeEntry.durationInSeconds;
        const workDayDuration =
            !isRunnning || !this.isToday
                ? workDayStopTime.diff(workDayStartTime, "seconds").as("seconds")
                : DateTime.now().diff(workDayStartTime, "seconds").as("seconds");

        return {
            workDayStartTime: workDayStartTime,
            workDayStopTime: workDayStopTime,
            workDayDuration: workDayDuration,
            timeEntry: timeEntry,
            startsToday: startsToday,
            endsToday: endsToday,
            isRunning: isRunnning
        };
    }

    removeTimeEntry(timeEntryId: number): void {
        const index = this.getTimeEntryIndex(timeEntryId);
        if (index < 0) {
            return;
        }

        this._timeEntries.splice(index, 1);
        this.calculateTotalWorkDayDuration();
    }

    calculateTotalWorkDayDuration(): void {
        if (!this.timeEntries.length) {
            this.totalDurationInSeconds = 0;
            return;
        }

        this.totalDurationInSeconds = this.timeEntries.map((te) => te.workDayDuration).reduce((a, b) => a + b, 0);
    }

    refreshCalculateTimeEntry(): void {
        const runningTimer = this.timeEntries.find((te) => te.isRunning);

        if (runningTimer) {
            this.remapTimeEntryChanges(runningTimer, runningTimer.timeEntry);
        }
    }

    private calculateIsToday(): void {
        this.isToday = this.date.hasSame(DateTime.now(), "day");
    }

    private getTimeEntryIndex(timeEntryId: number): number {
        return this._timeEntries.findIndex((te) => te.timeEntry.id === timeEntryId);
    }

    private sortTimeEntries(): void {
        this._timeEntries.sort((a, b) => b.timeEntry.startTime.valueOf() - a.timeEntry.startTime.valueOf());
    }
}
